import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck, faLongArrowAltRight, faCaretDown, faQuestion } from '@fortawesome/free-solid-svg-icons'

export default class OrderHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: false
        };
        this.toggleCard = this.toggleCard.bind(this);
        this.openModal = this.openModal.bind(this);
        if (this.props.openCards) {
            this.setState(()=>this.state.isToggleOn, true);
        } 
        else {
            this.setState(()=>this.state.isToggleOn, false);
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if (this.props.openCards !== prevState.isToggleOn) {
            this.setState({
                isToggleOn: this.props.openCards
            });
        }
    }

    toggleCard() {
        this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));
    }
    
    openModal() {
        console.log('toggle: ', this.state.isToggleOn);

        document.getElementById('modal').classList.add ('is-active');
        if (this.state.isToggleOn === true) {
            this.setState(state => ({
                isToggleOn: state.isToggleOn
            }));
        } else {
            this.setState(state => ({
                isToggleOn: true
            }));
        }
    }

    render() { 
        const renderHTML = (rawHTML) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });     
        return(
            <header className={this.state.isToggleOn ? ('card-header show '+this.props.backgroundClass+this.props.textColor) : ('card-header hide '+this.props.backgroundClass+this.props.textColor)} onClick={this.toggleCard} >
                <div className="level">
                    <div className={"card-header-title level-left"+this.props.textColor}>
                        <div>
                            <span className={"subtitle is-5"+this.props.textColor}>{this.props.data.Auftrag}&nbsp;{renderHTML(this.props.data.Name)}
                                <span className="is-size-6"> (vom {this.props.strErstellDatum})</span>
                                {this.props.AnmerkungIcon} 
                                {this.props.ulLabelIcon && <span className="ul-label">{this.props.ulLabelIcon}</span> }
                                {this.props.customerActionRequired && <FontAwesomeIcon icon={faQuestion} className="space-left" /> }
                            </span>      
                        </div>
                    </div>
                    <div className="card-header-title level-right">
                        <span className={"delivery"+this.props.textColor}><FontAwesomeIcon icon={faTruck} /><FontAwesomeIcon icon={faLongArrowAltRight} /><span className="land">{this.props.data.Land}</span></span>
                        <span className={"deliverydate subtitle is-5"+this.props.textColor}>{this.props.strLieferDatum}</span>
                        <span className={"toggle-arrow fa-2x"+this.props.textColor}><FontAwesomeIcon icon={faCaretDown} /></span>
                    </div>  
                </div>
            </header>  
        )
    }
}
