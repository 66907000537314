import React, { Component } from 'react';
import axios from '../axios-orders';
import OrderHeader from '../Orderlist/OrderHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots, faComments, faEdit } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown, faCaretUp, faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons';

function Order (props, openCards, onlyDue, onlyOnHold, withoutOnHold) {
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    // nur überfällige anzeigen
    let show = true;
    // comments vorhanden
    let comments = true;
    let ulLabel = false;
    let customerActionRequired = props.Status.indexOf('wait') > -1 ? true : false;

    // Zeit bis zum Liefertag berechnen
    let today = new Date();
    let timeDiff = (new Date(props.Lieferdatum).getTime() - today.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
    let backgroundClass = ' has-background-success';
    let textColor = '';
    
    let arrErstellDatum = props.Erstelldatum.split('-');
    let strErstellDatum = (Number(arrErstellDatum[2]) + '. ' + Number(arrErstellDatum[1]) + '. ' + arrErstellDatum[0]);
    let arrLieferDatum = props.Lieferdatum.split('-');
    let strLieferDatum = (Number(arrLieferDatum[2]) + '. ' + Number(arrLieferDatum[1]) + '. ' + arrLieferDatum[0]);
    
    if ( diffDays < 21) backgroundClass=" has-background-warning";
    if ( diffDays < 14) { 
      backgroundClass=" has-background-danger"; 
      textColor=" has-text-white"; 
    } else {
        if (onlyDue) { show = false }
    }
    if (onlyOnHold && !customerActionRequired) { show = false }
    if (withoutOnHold && customerActionRequired) { show = false }
console.log("withoutOnHold: ",withoutOnHold, customerActionRequired)

 
    if (show) {
        const Pos =  Object.keys(props.Positionen).map( key => {
            if (props.Positionen[key].ArtNr == "106") ulLabel = true;
            return(
                <tr id={key}>
                    <td className="nr">{props.Positionen[key].Anzahl}</td>
                    <td className="nr">{props.Positionen[key].ArtNr}</td>
                    <td className="bez">{renderHTML(props.Positionen[key].Bezeichnung)}</td> 
                    <td className="img">
                        <a href={"https://www.woka.com/de/suche.html?keywords=" + props.Positionen[key].ArtNr} title={props.Positionen[key].ArtNr + " auf woka.com suchen"} target="_blank">
                        <img src={"https://res.cloudinary.com/woka/image/upload/if_w_gt_30,c_scale,w_120/if_else,c_scale,w_6/d_pmc:products:noImg.png/pmc/products/" + props.Positionen[key].ArtNr + "-1.jpg"} />
                        </a>
                    </td>
                    <td>{renderHTML(props.Positionen[key].Hinweis)}</td> 
                </tr>
            )
        });
    
        let Anmerkung = '';
        let AnmerkungIcon = '';
        if (props.Anmerkung && String(props.Anmerkung).split(' ').join('').search(/^<br>{1,}/)) {
            Anmerkung = <div className="anmerkung box">{renderHTML(props.Anmerkung)}</div>;
            AnmerkungIcon = <span>&nbsp;&nbsp;<FontAwesomeIcon icon={faEdit} /></span>;
        }
        let CommentsIcon = '';
        if (comments) {
            CommentsIcon = <span>&nbsp;&nbsp;<FontAwesomeIcon icon={faComments} /></span>;
        }
        let ulLabelIcon = '';
        if (ulLabel) ulLabelIcon = 'UL';
          
        return (
            <div className="column">
                <div className="card">
                    <OrderHeader data={props} openCards={openCards} strErstellDatum={strErstellDatum} strLieferDatum={strLieferDatum} AnmerkungIcon={AnmerkungIcon} ulLabelIcon={ulLabelIcon} CommentsIcon={CommentsIcon} backgroundClass={backgroundClass} textColor={textColor} customerActionRequired={customerActionRequired} />
                    <div className="card-content">
                        <div className="content">
                            {Anmerkung}
                            <table className="table is-narrow">
                                <tbody>
                                    {Pos}   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return (<div></div>) ;
}
  

export default class Orderlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Orders: [],
            openCards: false,
            filterAuftrag: '',
            onlyDue: false,
            onlyDueCss: "",
            onlyOnHold: false,
            onlyOnHoldCss: "",
            withoutOnHold: false,
            withoutOnHoldCss: "",
            toolbar: "false",
            backgroundFilter: ""
        };
        if (props.toolbar==="true") this.setState({toolbar: "true"});
    }
       
    componentDidMount(props) {
        console.log("tb-m ", this.props.toolbar);
        axios.get().then(response => {
            const orders = response.data;
            console.log(orders)
            switch (this.props.sort) {
                case ("auftrag"): 
                    this.setState({ Orders: orders.sort(function(a, b) {
                        return a.Auftrag - b.Auftrag; })
                    });
                    break;
                default:
                    //sorting by Lieferdatum
                    this.setState({ Orders: orders.sort(function(a, b) {
                        return a.Lieferdatum.localeCompare(b.Lieferdatum); })
                    });
            }
            if (this.props.filter === "fabric") { 
                this.setState ({ 
                    backgroundFilter: "has-background-grey-dark",
                    Orders: orders.filter( order => {
                        let auftraegeMitStoffschirm = []
                        let hatStoffschirm = false 
                        
                        Object.keys(order.Positionen).map( key => {
                            console.log("Positionen ",order.Positionen[key].Stoffschirm)
                            if (order.Positionen[key].Stoffschirm !== "undefined" && order.Positionen[key].Stoffschirm === '1') {
                                hatStoffschirm = true
                            }
                            if ( hatStoffschirm ) auftraegeMitStoffschirm.push(order.Auftrag)
                        }) 
                        return auftraegeMitStoffschirm.join("||")
                    })
                })
            } else {
                this.setState ({ 
                    backgroundFilter: ""
                })
            }
            if (this.props.filter === "ul") {
                this.setState ({ 
                    backgroundFilter: "has-background-grey-dark",
                    Orders: orders.filter( order => {
                        let auftraegeMitUl = []
                        let hatUl = false 
                        
                        Object.keys(order.Positionen).map( key => {
                            if (order.Positionen[key].ArtNr === '106') {
                                hatUl = true
                            }
                            if ( hatUl ) auftraegeMitUl.push(order.Auftrag)
                        }) 
                        return auftraegeMitUl.join("||")
                    })
                })
            }
        });

        

      // Burger Menü
      console.log(document.getElementById('toolbar-burger'))  ;
        
        const $toolbarBurgers = document.getElementById('toolbar-burger');

        // Burger Menü
        // Check if there are any navbar burgers
        if (document.getElementById('toolbar-burger')) {
   
          // Add a click event on each of them
          $toolbarBurgers.addEventListener('click', () => {
      
              // Get the target from the "data-target" attribute
              const $target = document.getElementById('filter');
              const $toolbarBurgerElements = Array.prototype.slice.call(document.querySelectorAll('#filter div button'), 0);
      
              // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
              document.getElementById('toolbar-burger').classList.toggle('is-active');
              $target.classList.toggle('is-active');

              // Add a click event on each of them and remove class is-active at each click to hide navmenu
              $toolbarBurgerElements.forEach( navEl => {
                navEl.addEventListener('click', () => {
                  document.getElementById('toolbar-burger').classList.remove('is-active');
                  $target.classList.remove('is-active');
                }); 
              }); 
            });
        }
        
    }

    componentDidUpdate(prevProps,prevState) {
    console.log("tb-u ", this.props.toolbar);
        if (this.props.sort !== prevProps.sort || this.props.filter !== prevProps.filter) {
            axios.get().then(response => {
                const orders = response.data;
                switch (this.props.sort) {
                    case ("auftrag"): 
                        this.setState({ Orders: orders.sort(function(a, b) {
                            return a.Auftrag - b.Auftrag; })
                        });
                        break;
                    default:
                        //sorting by Lieferdatum
                        this.setState({ Orders: orders.sort(function(a, b) {
                            return a.Lieferdatum.localeCompare(b.Lieferdatum); })
                        });
                }
                if (this.props.filter === "fabric") { 
                    this.setState ({ 
                        backgroundFilter: "has-background-grey-dark",
                        Orders: orders.filter( order => {
                            let auftraegeMitStoffschirm = []
                            let hatStoffschirm = false 
                            
                            Object.keys(order.Positionen).map( key => {
                                if (order.Positionen[key].Stoffschirm !== "undefined" && order.Positionen[key].Stoffschirm === '1') {
                                    hatStoffschirm = true
                                }
                                if ( hatStoffschirm ) auftraegeMitStoffschirm.push(order.Auftrag)
                            }) 
                            return auftraegeMitStoffschirm.join("||")
                        })
                    })
                } else {
                    this.setState ({ 
                        backgroundFilter: "",
                    })
                }
                if (this.props.filter === "ul") {
                    this.setState ({ 
                        backgroundFilter: "has-background-grey-dark",
                        Orders: orders.filter( order => {
                            let auftraegeMitUl = []
                            let hatUl = false 
                            
                            Object.keys(order.Positionen).map( key => {
                                if (order.Positionen[key].ArtNr === '106') {
                                    hatUl = true
                                }
                                if ( hatUl ) auftraegeMitUl.push(order.Auftrag)
                            }) 
                            return auftraegeMitUl.join("||")
                        })
                    })
                }
            })
        }
     /*   
        console.log(document.getElementById('toolbar-burger'))  ;
        //const $toolbarBurgers = Array.prototype.slice.call(document.getElementById('toolbar-burger'), 0);
        const $toolbarBurgers = document.getElementById('toolbar-burger');

        // Burger Menü
        // Check if there are any navbar burgers
        if (document.getElementById('toolbar-burger')) {
   
          // Add a click event on each of them
          $toolbarBurgers.addEventListener('click', () => {
      
              // Get the target from the "data-target" attribute
              const $target = document.getElementById('filter');
              const $toolbarBurgerElements = Array.prototype.slice.call(document.querySelectorAll('#filter div button'), 0);
      
              // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
              document.getElementById('toolbar-burger').classList.toggle('is-active');
        console.log('$target-toogle: ',$target);
              $target.classList.toggle('is-active');

              // Add a click event on each of them and remove class is-active at each click to hide navmenu
              /*$toolbarBurgerElements.forEach( navEl => {
                navEl.addEventListener('click', () => {
                  document.getElementById('toolbar-burger').classList.remove('is-active');
                  $target.classList.remove('is-active');
                }); 
              });
            });
        }*/

    }
    
    render() { 
                
        return(
           <div className={ this.state.backgroundFilter }> 
                <div className="navbar toolbar is-fixed-top">
                    <div className="container">

                        <div id="toolbar-burger" className="navbar-burger burger" data-target="filter" aria-expanded="false">
                            <div className="">Filter</div>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </div>

                        <div className="level navbar-menu" id="filter">
                            <div className="level-left navbar-start">
                                <div className="level-item navbar-item">
                                    Alle&nbsp;&nbsp;<span className="buttons has-addons"><button className="button has-background-grey-light" onClick={() => this.setState({ openCards: true })}><FontAwesomeIcon icon={faCaretDown} />&nbsp;ausklappen</button> <button className="button has-background-grey-light" onClick={() => this.setState({ openCards: false })}><FontAwesomeIcon icon={faCaretUp} />&nbsp;einklappen</button></span>
                                </div>
                                <div className="level-item navbar-item">
                                    <div className="buttons has-addons">
                                        <button className={`button filter ${this.state.onlyDueCss}`} onClick={() => { if(this.state.onlyDue) this.setState({ onlyDueCss: '',onlyDue: false }); else this.setState({ onlyDueCss: ' is-active', onlyDue: true });}}>nur fällige</button>
                                        <button className={`button filter ${this.state.onlyOnHoldCss}`} onClick={() => { if(this.state.onlyOnHold) this.setState({ onlyOnHold: false, onlyOnHoldCss: '' }); else this.setState({ onlyOnHold: true, withoutOnHold: false, onlyOnHoldCss: ' is-active', withoutOnHoldCss: ''})}}>nur <FontAwesomeIcon icon={faQuestion} className="space-left" /></button>
                                        <button className={`button filter ${this.state.withoutOnHoldCss}`} onClick={() => { if(this.state.withoutOnHold) this.setState({ withoutOnHold: false, withoutOnHoldCss: '' }); else this.setState({ withoutOnHold: true, onlyOnHold: false, withoutOnHoldCss: ' is-active', onlyOnHoldCss: ''})}}>ohne <FontAwesomeIcon icon={faQuestion} className="space-left" /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="level-right navbar-end">
                                <div className="level-item navbar-item">
                                    <div className="field-label">
                                        <label className="label">Filter</label>
                                    </div>
                                    <div className="field has-addons">
                                        <div className="control">
                                            <input id="ordernofilter" className="input" type="text" value={this.state.filterAuftrag} onChange={ this.handleChange.bind(this) } placeholder="Auftrag Nr." />
                                        </div>
                                        <div className="control">
                                            <button className="button is-dark" onClick={ this.resetFilter.bind(this) } ><FontAwesomeIcon icon={faTimes} /></button>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section">
                    <div className="container">
                        <div id="orders">
                            {this.state.Orders.filter(item=>(item.Auftrag+'').match(this.state.filterAuftrag+'.*')).map( o => new Order(o, this.state.openCards, this.state.onlyDue, this.state.onlyOnHold, this.state.withoutOnHold ))} 
                        </div>
                    </div>
                </section>
            </div>          
        )
    }

    handleChange(e) {
        this.setState({ filterAuftrag: e.target.value });
    }
    resetFilter(e) {
        this.setState({ filterAuftrag: "" });
    }
}



Orderlist.defaultProps = {
    sort: ""
}