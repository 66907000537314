import React, { Component } from 'react';
import axios from '../axios-lamplist';

export default class OrderedLamps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Lamplist: [],
            loading: true
        };
    }

    componentDidMount(props) {
        axios.get()
            .then(response => {
                const lamps = response.data;   
                this.setState({ Lamplist: lamps, loading: false }); 
            })
            .catch(function (error) {
                console.log(error);
            }); 
    }

    render() {
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return(
            <section className="section">
            <div className="container">
            <table className="table is-narrow is-striped">
                    <thead>
                        <tr>
                            <th>ArtNr.</th>
                            <th>Bez.</th>
                            <th>Anzahl</th>
                            <th>Aufträge</th>
                        </tr>
                    </thead>
                    
                { this.state.loading &&  <tbody><tr><td>&nbsp;Lade Daten...</td></tr></tbody> }
                { !this.state.loading &&
                    <tbody>
                        {Object.keys(this.state.Lamplist).map((key) => {
                            if ( key !== "106" ) { // keine UL additional costs
                            return (
                                <tr> 
                                    <td className="has-text-right">{key}</td> 
                                    <td> 
                                        {renderHTML(this.state.Lamplist[key].Bezeichnung)} 
                                    </td> 
                                    <td> 
                                        {this.state.Lamplist[key].Anz} 
                                    </td> 
                                    <td> 
                                        {this.state.Lamplist[key].Auftraege.map(el => { if (el !== this.state.Lamplist[key].Auftraege[this.state.Lamplist[key].Auftraege.length -1]) return(el +", "); else return el; })} 
                                    </td> 
                                </tr>
                            )}
                            })
                        }
                    </tbody>
                }
                </table>
            </div>
            </section>
        )
    }
}