// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'eu-central-1:dc18e4b3-4f45-4b38-abe2-7b809b2ee47b',
    'aws_cognito_region': 'eu-central-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'wokapmc-hosting-mobilehub-1004431305',
    'aws_content_delivery_bucket_region': 'eu-central-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'ddvb9tfwljpiw.cloudfront.net',
    'aws_mobile_analytics_app_id': '7c06899b0ee84792a9a2b66e99eb3e96',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': 'e64795b3-75cd-4698-931c-e0e6e82d2661',
    'aws_project_name': 'woka-pmc-2018-07-02-13-54-00',
    'aws_project_region': 'eu-central-1',
    'aws_resource_name_prefix': 'wokapmc-mobilehub-1004431305',
    'aws_sign_in_enabled': 'enable',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'eu-central-1_bCsdF2VGQ',
    'aws_user_pools_web_client_id': '4lmamf2k3bcmioatpeqm569ptc',
}

export default awsmobile;
