import React, { Component } from 'react';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import Orderlist from './Orderlist/Orderlist';
import OrderedLamps from './OrderedLamps/OrderedLamps';
//import { Header } from './header';
//import logo from './logo.svg';
import './App.css';
import 'bulma/css/bulma.css'

import { withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth } from 'aws-amplify';
import { I18n } from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);

I18n.setLanguage('de');
const dict = {
  'de': {
      'Hello': "Hallo",
      'Sign In': "Login",
      'Sign Out': "Logout",
      'Sign Up': "registrieren",
      'Username': "Benutzername",
      'Password': "Passwort",
      'Sign In Account': "Log In",
      'Forgot Password': "Passwort vergessen",
      'Send Code': "Code senden",
      'Back to Sign In': "Zurück zum Login"
  }
};

I18n.putVocabularies(dict);


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        user: []
    };
}

  componentDidMount() {
    console.log('mount');
      Auth.currentAuthenticatedUser()
          .then(user => this.setState({ user: user }))
          .catch(err => this.setState({ user: null }));
  
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.mainnav'), 0);

      // Burger Menü
      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {
    console.log($navbarBurgers.length)  ;
          // Add a click event on each of them
          $navbarBurgers.forEach( el => {
              el.addEventListener('click', () => {
      
              // Get the target from the "data-target" attribute
              const target = el.dataset.target;
              const $target = document.getElementById(target);
              const $navbarBurgerElements = Array.prototype.slice.call(document.querySelectorAll('.navbar div a'), 0);
      
              // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
              el.classList.toggle('is-active');
              $target.classList.toggle('is-active');

              // Add a click event on each of them and remove class is-active at each click to hide navmenu
              $navbarBurgerElements.forEach( navEl => {
                navEl.addEventListener('click', () => {
                  el.classList.remove('is-active');
                  $target.classList.remove('is-active');
                });
              });
            });
          });
        }
        
  }

  componentDidUpdate(props) {
   // const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
   // console.log($navbarBurgers.length)  ;
  }


  
  closeModal() {
     document.getElementById('modal').classList.remove('is-active');
  }
  
  render() {

    const listOrders = (props) => {
      return (
        <Orderlist 
          toolbar="true"
          sort=""
          filter=""
        />
      );
    }
    const listOrdersWithFabric = (props) => {
      return (
        <Orderlist 
          toolbar="true" 
          sort=""
          filter="fabric"
        />
      );
    }
    const listOrdersWithUl = (props) => {
      return (
        <Orderlist 
          toolbar="true" 
          sort=""
          filter="ul"
        />
      );
    }
    const listByOrderNo = (props) => {
      return (
        <Orderlist 
          toolbar="true" 
          sort="auftrag"
          filter=""
        />
      );
    }

    return (
    <div className="App">
      <BrowserRouter>
        <div>
        <div id="modal" className='modal' onClick={this.closeModal}>
                    <div className="modal-background"></div>
                    <div className="modal-content has-background-white">
                        <p>Hello Modal!</p>
                    </div>
                    <button className="modal-close is-large" aria-label="close"></button>
                </div>   
          <header className="App-header">   
            <nav className="navbar is-dark is-fixed-top" role="navigation">
              <div className="container">
                <div className="navbar-brand">
                  <span className="navbar-item">
                    <div className="title">
                      WOKA-PMC
                    </div>
                  </span>
                  <div className="navbar-burger burger mainnav" data-target="navbarWOKA-PMC" aria-expanded="false">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </div>
                </div>
                <div id="navbarWOKA-PMC" className="navbar-menu">
                  <div className="navbar-start">
                    <div className="navbar-item has-dropdown is-hoverable">
                        <Link to="/auftraege" className="navbar-link" sort="" filter="">Aufträge</Link>
                      <div className="navbar-dropdown">
                        <Link to="/auftraege-nach-auftragsnummer" className="navbar-item has-text-white has-background-dark" sort="Auftrag">nach Auftragsnummer</Link>
                        <Link to="/auftraege-mit-stoffschirm" className="navbar-item has-text-white has-background-dark" filter="fabric">mit Stoffschirm</Link>
                        <Link to="/auftraege-mit-ul-label" className="navbar-item has-text-white has-background-dark" filter="fabric">mit UL Labeling</Link>
                      </div>
                    </div>
                    <Link to="/lampen" className="navbar-item">bestellte Lampen</Link>
                  </div>
                  <div className="navbar-end">
                    <div className="navbar-item"><i className="fas fa-sync reload fa-2x"></i></div>
                  </div>
              </div>
            </div>
          </nav>
      </header>
      
    <Switch>
      <Route path="/" component={Orderlist} exact={true} />
      <Route path="/auftraege" render={listOrders} exact={true} />
      <Route path="/auftraege-nach-auftragsnummer" render={listByOrderNo} exact={true} />
      <Route path="/auftraege-mit-stoffschirm" render={listOrdersWithFabric} exact={true} />
      <Route path="/auftraege-mit-ul-label" render={listOrdersWithUl} exact={true} />
      <Route path="/lampen" component={OrderedLamps} />
    </Switch>
    </div>
    
          
    </BrowserRouter>

    </div>       
    );
  }
}

export default withAuthenticator(App,true);
